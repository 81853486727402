import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AuthService } from 'src/app/helpers/services/auth.service';
import { ToasterLoaderService } from 'src/app/helpers/services/toaster-loader.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss', '../../login/login.page.scss'],
  standalone: true,
  imports: [IonicModule, ReactiveFormsModule]
})
export class ResetPasswordComponent  implements OnInit {

  resetPasswordForm: FormGroup;
  email: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toasterLoaderService: ToasterLoaderService,
    private authService: AuthService,
    private router: Router 
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((response:any) => {
      this.email = response.email;
    });
  }

  onSubmit() {
    const { password, confirm_password } = this.resetPasswordForm.value;
    let email = this.email;

    if (this.resetPasswordForm.valid) {
      if (password === confirm_password) {
        this.toasterLoaderService.loaderShow();
        this.authService.resetPassword({email, password, confirm_password}).subscribe((response:any) => {
          this.toasterLoaderService.hideLoader();
          if (response.success) {
            this.toasterLoaderService.toastMessage(response.message, 'success');
            this.router.navigate(['auth/login']);
          } else {
            this.toasterLoaderService.toastMessage(response.message, 'danger');
          }
        })
        
      } else {
        this.toasterLoaderService.toastMessage('Passwords do not match, please try again', 'danger');
      }
    } else {
      this.toasterLoaderService.toastMessage('Please fill in the required fields', 'danger');
    }
  }
  
  cancel() {
    this.router.navigate(['auth/login']);
  }

}
