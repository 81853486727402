import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToasterLoaderService {

  private loader: any;
  constructor(
    private toastController: ToastController,
    private loaderController: LoadingController
  ) { }

  /**
   * Displays a toast message on the screen.
   * 
   * @param string message - The message to display in the toast.
   * @param string color - The color of the toast.
   * @param string[] buttons - Optional. An array of buttons to display on the toast. Default is ['OK'].
   * @returns A Promise that resolves when the toast is presented.
   */
  async toastMessage(message: string, color: string, buttons: string[] = ['OK']) {
    const toast = await this.toastController.create({
      message,
      position: 'bottom',
      color,
      buttons,
      cssClass: 'color-white',
      duration: 3000,
    });

    await toast.present();
  }

  async loaderShow(message?: any) {
    this.loader = await this.loaderController.create({
      message,
      backdropDismiss: false,
      spinner: 'circles',
      cssClass: 'dark-bg-main'
    })

    await this.loader.present();
  }

  async hideLoader() {
    if(this.loader) {
      await this.loader.dismiss();
      this.loader = null;
    }
  }


}
