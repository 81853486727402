export const APIENDPOINT = {
    AUTH: {
        LOGIN: 'auth/login',
        LOGOUT: 'auth/logout',
        FORGOTPASSWORD: 'auth/password-reset-email',
        RESETPASSWORD: 'auth/reset-password'
    },
    PROSPECT: {
        ACTIVE: 'prospect/active-prospects',
        CLOSED: 'prospect/closed-prospects',
        DETAIL: 'prospect/prospect-detail/', //prospectId
        ASSIGN: 'prospect/assign/', //prospectId/{user_id}
        CHANGESTATUS: 'prospect/change-status/', //prospect/change-status/{universityId}/{status}
        CHANGESTAGE: 'prospect/change-stage/', //prospect/change-stage/{universityId}/{stage}
        UPLOADDOCUMENT: (prospectId: number) => `prospect/${prospectId}/upload-document`, //prospect/{universityId}/upload-document
        GETDOCUMENTS: (prospectId: number, offset: number) => `prospect/${prospectId}/get-documents/${offset}`, //prospect/{universityId}/get-documents/{offset?}
        ADDNOTE: (prospectId: number) => `prospect/${prospectId}/add-note`, //prospect/{universityId}/add-note
        GETNOTES: (prospectId: number, offset: number) => `prospect/${prospectId}/get-notes/${offset}`, //prospect/{universityId}/add-note/{offset?}
    },
    CLIENT: {
        LIST: 'client/list', //list/{status}/{offset?}/{name?}
        DETAIL: 'client/client-detail/', //client/client-detail/{id}
        GETDOCUMENTS: (clientId: number, offset: number) => `client/${clientId}/get-documents/${offset}`, //prospect/{universityId}/get-documents/{offset?}
        ASSIGN: 'prospect/assign/', //universityId/{user_id}
    },
    USERS: {
        GETSOCIELITEADMINS: 'users/socielite-admins', //users/socielite-admins/{offset}/{name}
        STUDENTS: {
            ALL: 'users/students/all/', //{universityId}
            IMPORT: 'users/students/import'
        }
    },
    QUERY: {
        LIST: 'query/university/queries/', //{universityId}/{offset}
        CHATS: 'query/query-chats/', //{universityId}/{queryId}/{offset},
        REPLY: 'query/socielite-admin/reply-query' //{query_id, content, university_id, sender_id}
    },
    COURSES: {
        ALL: 'courses/all/', // {universityId}
        IMPORT: 'courses/import',
    },
    COMMUNICATON: {
        COMMUNICATION: 'communication/get/',
        LIST: 'communication/all-communication/',
        SEND: 'communication/send',
        EDIT: 'communication/edit/'
    },
    FAQ: {
        FAQ: 'faq/get/',
        LIST: 'faq/all-faq/',
        POST: 'faq/create',
        EDIT: 'faq/edit/',
        DELETE: 'faq/delete/'
    },
    TUTORIAL:{
        TUTORIAL:'tutorial/get/',
        LIST:'tutorial/all-tutorials/',
        POST: 'tutorial/create',
        EDIT:'tutorial/edit/',
        DELETE:''
    },

    TEAMMEMBER:{
        TEAMMEMBER:'team-member/get/',
        LIST:'team-member/all',
        POST:'team-member/get/',
        EDIT:'team-member/edit'
    }

}