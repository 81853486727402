<div class="my-flex ion-justify-content-end ion-align-items-center ion-margin cursor">
  <ion-icon name="close-outline" (click)="close()"></ion-icon>
</div>
<div class="height-webkit-fill " style="display: flex;
    justify-content: center;">
  <form class="ion-padding-bottom" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
    <h1 class="gradient-text ion-padding-bottom bolder">
      LET'S GET YOU BACK ONLINE.
    </h1>
    <div class="margin-bottom-10">
      <ion-label class="ion-padding-start">EMAIL</ion-label>
    </div>
    <div class="ion-margin-bottom">
      <div class="form-input-wrapper">
        <div class="form-input-color">
          <ion-input fill="outline" shape="round" class="ion-padding-start" formControlName="email" placeholder="University Email"></ion-input>
        </div>
      </div>
    </div>
    <div class="margin-bottom-10">
      <ion-label class="ion-padding-start">CONFIRM EMAIL</ion-label>
    </div>
    <div class="ion-margin-bottom ion-padding-bottom">
      <div class="form-input-wrapper">
        <div class="form-input-color">
          <ion-input fill="outline" shape="round" class="ion-padding-start" formControlName="confirm_email" placeholder="University Email"></ion-input>
        </div>
      </div>
    </div>

    <div class="ion-margin-top ion-padding-top button-border">
      <div class="button-background">
        <ion-button size="small" class="button-color" shape="round" expand="full" type="submit">Send Recovery Password</ion-button>
      </div>
    </div>
  </form>
</div>