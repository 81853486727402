import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular'
@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;

  constructor(
    private storage: Storage
  ) { 
    this.init();
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  public set(key: string, value: any){
    this._storage?.set(key, value);
  }

  public get(key: string){
    return this._storage?.get(key);
  }

  public remove(key:string){
    this._storage?.remove(key);
  }

  public clearStorage(){
    this._storage?.clear();
  }

  public async getToken() {
    let token = await this._storage?.get('token');
    if (token) {
      return token || null;
    }
    return token;
  }

  public async getUser() { 
    let user = await this._storage?.get("user");
    if (user) {
      return user || null;
    }
    return user;
  }
}
