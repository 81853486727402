import { Injectable } from '@angular/core';
import { REGEXEMAIL } from '../constant/regex';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  constructor(
    private toastController: ToastController
  ) { }

  /**
   * Validates if the provided email string is in a valid email format.
   * 
   * @param email - The email string to validate.
   * @returns A boolean indicating if the email is valid.
   */
  isValidEmail(email: string) {
    return REGEXEMAIL.test(email);
  }
}
