import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter, withPreloading, PreloadAllModules } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { TokenInterceptorService } from './app/helpers/services/token-interceptor.service';
import { NgxTinymceModule } from 'ngx-tinymce';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideRouter(routes, withPreloading(PreloadAllModules)),

    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(IonicModule),
    importProvidersFrom(
      IonicStorageModule.forRoot({
        name: '_socielite',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
      }),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    importProvidersFrom(
      NgxTinymceModule.forRoot({
        baseURL: './assets/tinymce/'
      })
    )
  ],
});
