import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, FormBuilder, FormGroup,Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { IonicModule } from '@ionic/angular';
import { REGEXEMAIL } from 'src/app/helpers/constant/regex';
import { VerificationService } from 'src/app/helpers/services/verification.service';
import { ToasterLoaderService } from 'src/app/helpers/services/toaster-loader.service';
import { StorageService } from 'src/app/helpers/services/storage.service';
import { ROLE } from 'src/app/helpers/constant/role';
import { AuthService } from 'src/app/helpers/services/auth.service';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, ForgotPasswordComponent],
})
export class LoginPage implements OnInit {
  loginForm: FormGroup;
  errorMessage: string = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private modalController: ModalController,
    private verificationService: VerificationService,
    private toasterLoaderService: ToasterLoaderService,
    private storageService: StorageService
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      rememberMe: [false],
    });
  }

  ngOnInit() {}

  onLogin() {
    const { email, password } = this.loginForm.value;

    if (this.loginForm.valid) {
      if (this.verificationService.isValidEmail(email)){
        this.toasterLoaderService.loaderShow();
        this.authService.login({email, password}).subscribe((response:any) => {
          this.toasterLoaderService.hideLoader();
          if (response.success) {
            let user = response.user;
            this.storageService.set('user', user);
            this.storageService.set('token', user?.token);
            this.storageService.set('role', user?.roles);
            if (user?.roles.includes(ROLE.SOCIELITEADMIN)) {
              this.router.navigate(['/socielite-admin/home']);
              console.log('/socielite-admin/dashboard');
            } else if (user?.roles.includes(ROLE.UNIVERSITYADMIN)) {
              this.router.navigate(['/university-admin/dashboard']);
              console.log('/university-admin/dashboard');
            } else {
              this.router.navigate(['/student/dashboard']);
              console.log('/student/dashboard');
            }
          } else {
            this.toasterLoaderService.toastMessage('Invalid Email or Password Provided, Please try agian.', 'danger');
          }
        })
      } else {
        this.toasterLoaderService.toastMessage('Invalid Email Provided', 'danger');
      }
    } else {
      this.toasterLoaderService.toastMessage('Please fill in the required fields', 'danger');
    }
  }

  async openForgotPasswordModal(type: 'modal'|'popup'){

    const modal = await this.modalController.create({
      component: ForgotPasswordComponent,
      cssClass: type === 'popup' ? 'popup-modal' : 'dark-bg-main',
      breakpoints: type === 'popup' ? [] : [0.55],
      initialBreakpoint: type === 'popup' ? undefined : .55 
    })
    modal.present();
  }

  logout() {
    this.authService.logout().subscribe((response:any) => {
      if (response.success) {
        this.toasterLoaderService.toastMessage('success', 'sucess');
      } else {
        this.toasterLoaderService.toastMessage('danger', 'danger')
      }
    });
  }
}
