import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ModalController } from '@ionic/angular/standalone';
import { AuthService } from 'src/app/helpers/services/auth.service';
import { ToasterLoaderService } from 'src/app/helpers/services/toaster-loader.service';
import { VerificationService } from 'src/app/helpers/services/verification.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  standalone: true,
  imports: [IonicModule, ReactiveFormsModule],
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent  implements OnInit {

  forgotPasswordForm: FormGroup;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private toasterLoaderService: ToasterLoaderService,
    private verificationService: VerificationService,
    private authService: AuthService
  ) {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      confirm_email: ['', [Validators.required]]
    });
  }

  ngOnInit() {}

  close() {
    this.modalController.dismiss();
  }

  onSubmit(){
    const { email, confirm_email } = this.forgotPasswordForm.value;

    if (this.forgotPasswordForm.valid) {
      if (this.verificationService.isValidEmail(email)) {
        if (email === confirm_email) {
          this.toasterLoaderService.loaderShow();
          this.authService.forgotPassword({email}).subscribe((response:any) => {
            this.toasterLoaderService.hideLoader();
            if (response.success) {
              this.toasterLoaderService.toastMessage(response.message, 'success');
              this.modalController.dismiss();
            } else {
              this.toasterLoaderService.toastMessage(response.message, 'danger');
            }
          })
        } else {
          this.toasterLoaderService.toastMessage('Please ensure that the emails entered match each other', 'danger');
        }
      } else {
        this.toasterLoaderService.toastMessage('Please enter a valid email', 'danger');
      }
    } else {
      this.toasterLoaderService.toastMessage('Please fill in the required fields', 'danger');
    }
  }
}
