import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { APIENDPOINT } from '../constant/api-endpoints';
import { catchError, Observable, of, throwError, from, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { LoadingController } from '@ionic/angular';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  excludeURLs: any = {
    login: environment.baseUrl + APIENDPOINT.AUTH.LOGIN
  };

  constructor(
    private router: Router,
    private storageService: StorageService,
    private loading: LoadingController
  ) { }

  /**
   * Intercepts HTTP requests to set the bearer token and handle errors.
   *
   * @param request - The outgoing HTTP request.
   * @param next - The next handler in the chain.
   * @returns An Observable of the HTTP event.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Convert getToken() to a promise and handle undefined case
    const tokenPromise = this.storageService.getToken() || Promise.resolve('');

    return from(tokenPromise).pipe(
      switchMap((token) => {
        let usedRequest: HttpRequest<any> = request;

        // Add the authorization header if the request is not for login or Google services
        if (!request.url.includes(this.excludeURLs.login) && !request.url.includes('google')) {
          usedRequest = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        // Append the timezone to the request parameters if the request is not for Google services
        if (!request.url.includes('google')) {
          let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
          usedRequest = usedRequest.clone({ params: usedRequest.params.append('timezone', timezone) });
        }

        // Handle the request and catch errors
        return next.handle(usedRequest).pipe(
          catchError((error) => {
            let handled: boolean = false;
            if (error instanceof HttpErrorResponse) {
              this.loading.dismiss().finally(() => null);
              // Handle different HTTP status codes
              switch (error.status) {
                case 401:
                  if (!this.router.url.includes('auth/login')) {
                    this.storageService.clearStorage();
                    this.router.navigate(["auth/login"]);
                    handled = true;
                  }
                  break;
              }
            }
            // Return the error as an observable
            if (handled) {
              return of(error);
            } else {
              return throwError(error?.error ? error.error : error);
            }
          })
        );
      })
    );
  }
}
